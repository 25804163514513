import { FAVOURITE_STORIES_READ_TABLE } from '../Actions/type'

const INITIAL_STATE = {
    fav_stories_table: {},

}
const FavReadTableReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FAVOURITE_STORIES_READ_TABLE:
            return { ...state, ...INITIAL_STATE, 'fav_stories_table': action.data }
        default:
            return state
    }
};

export default FavReadTableReducers;