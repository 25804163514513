import React, { Component } from 'react';
import _ from 'lodash'

class Favourites extends Component {


    renderFavouritesList = () => {

        if (this.props.favourite_list) {
            let sortData = _.sortBy(this.props.favourite_list, ["type", "total_favourite"]).reverse();
            return _.map(sortData, (item, key) => {
                return (
                    <li className="story_list_title" key={key}>
                        <span>{item.story_name}</span>
                        <span>{item.total_favourite}</span>
                    </li>
                )
            })
        }
    }
    render() {

        return (
            <div className="favourites_wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card_hover">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span className="favourite_title">Favorite Stories</span>
                                        <span className="favourite_slogan">Represented by Total Number of Favorites</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                    <span className="stories_header">Stories</span>
                                        <ul className="favourite_list">
                                            {this.renderFavouritesList()}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Favourites;