import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom'


let organization = ''
let user = JSON.parse(localStorage.getItem("ecrypt_data"))
    ? JSON.parse(localStorage.getItem("ecrypt_data"))
    : "";

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            cell_index: '',
        }

    }

    componentDidMount = () => {
        this.props.GetHcode();
        this.props.Pagination();
    }
   
    componentWillReceiveProps = (props) => {
        organization = props.getorg.org_code;
        let data=[];
        _.map(organization, (i, key) => {
           data.push({ id: key, org_code: i.org_code, nick_name: i.nick_name })
        })

        this.setState({
            data
        })

        if (props.details && props.details.user) {

            // this.refs.curr_pwd.value = ""
            this.refs.new_pwd.value = ""
            this.refs.confirm_new_pwd.value = ""
        }
    }

  


    // Type  password for change
    changeSubmit = (e) => {
        e.preventDefault();
        // let curr_pwd = this.refs.curr_pwd.value
        let new_pwd = this.refs.new_pwd.value
        let confirm_new_pwd = this.refs.confirm_new_pwd.value

        let form_details = {
            "password": new_pwd,
            "confirm_password": confirm_new_pwd
        }
        this.props.updateProfile(form_details);


    }

    // click on edit code
    EditCode = (code) => {
        let index = this.state.data.indexOf(code)

        this.setState({ cell_index: index, cell_edit: true })
        setTimeout(() => {
            this.refs["nickname" + index].value = code.nick_name
        }, 100);
    }

    // click on update code
    UpdateCode = (code) => {
        let index = this.state.data.indexOf(code)
        this.setState({ cell_index: '', cell_edit: false });
        this.props.updateOrgCode({ "org_code": code.org_code, "nick_name": this.refs["nickname" + index].value })
    }

    // type new org code
    OnCodeChange = (e, code) => {
        let index = this.state.data.indexOf(code)
        this.refs["nickname" + index].value = e.target.value
    }

    // cancel edit
    cancel = () => {
        this.setState({
            cell_index: '',
            cell_edit: false
        })
    }
    // render ORGCODE
    renderTable = () => {
        const { data } = this.state;
        const indexOfLastData = this.props.pagination.currentPage * this.props.pagination.perPage;
        const indexOfFirstData = indexOfLastData - this.props.pagination.perPage;
        const currentTodos = data.slice(indexOfFirstData, indexOfLastData);



        return _.map(currentTodos, (iteam, i) => {
            return (
                <tr key={i}>
                    <td>{iteam.org_code}</td>
                    <td>
                        {this.state.cell_index === iteam.id ?
                            <input type="text" className="form-control" name={'nickname' + iteam.id} id={'nickname' + iteam.id} ref={'nickname' + iteam.id} onChange={(e) => { this.OnCodeChange(e, iteam) }} />
                            : iteam.nick_name}
                    </td>
                    <td className="text-center">
                        <span>
                            {this.state.cell_index === iteam.id ?
                                <React.Fragment>
                                    <i className="fa fa-save" onClick={(e) => { this.UpdateCode(iteam) }}></i>
                                    <i className="fa fa-close" onClick={(e) => { this.cancel() }}></i>
                                </React.Fragment> :
                                <i className="fa fa-pencil" onClick={(e) => { this.EditCode(iteam) }}></i>
                            }
                        </span>
                    </td>
                </tr>
            )
        })
    }

    // Next page and Previous
    Pagination = (e) => {
        e.preventDefault();
        this.props.NextPage(Number(e.target.id))
    }

    // renderPAgination
    renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(this.state.data.length / this.props.pagination.perPage); i++) {
            pageNumbers.push(i);
        }

        return _.map(pageNumbers, (iteam, i) => {
            return (
                <li className="" className={iteam === this.props.pagination.currentPage ? 'page-item active' : 'page-item'} key={i}><a className="page-link" href="#" id={iteam} onClick={(e) => { this.Pagination(e) }}>{i + 1}</a></li>
            )
        })
    }

    render() {
        return (
            <div className="setting_wraper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <h1>Settings</h1>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"><a href="#">Library</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row ">
                        {/* <div className="col-xl-9 col-lg-9 col-md-9">
                            <div className="card table_card">
                                <div className="card-header">
                                    <span>Organization Code</span>
                                </div>
                                <div className="card-body ">
                                    <table className="table react-bs-table">
                                        <thead>
                                            <tr>
                                                <th>Original Code</th>
                                                <th>New Code Name</th>
                                                <th className="text-center">Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderTable()}
                                        </tbody>
                                    </table>
                                    <ul className="pagination">
                                        {!this.state.cell_edit ?
                                            this.renderPagination() : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-3 col-lg-3 col-md-3">
                            <div className="card change_pwd_wrapper">
                                <div className="card-body">
                                    <h2>Change Password</h2>
                                    <form className="mt-3 font-weight-bold"
                                        onSubmit={(e) => { this.changeSubmit(e) }}
                                    >
                                        <label className="err_msg">{this.props.Profile.err_msg}</label>
                                        <div className="form-group">
                                            <label>Password</label>
                                            {/* <input type="password" name="curr_pwd" className="form-control" ref="curr_pwd" placeholder="Current Password" /> */}
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="curr_pwd" className="form-control" ref="new_pwd" placeholder="New Password" />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="curr_pwd" className="form-control" ref="confirm_new_pwd" placeholder="Confirm New Password" />
                                        </div>
                                        <div className="form-group text-right">
                                            <button type="submit">Confirm</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loader ? <div className="loading"></div> : ''}
            </div>

        );
    }
}

export default Setting;