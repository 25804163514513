import React, { Component } from 'react';
import _ from 'lodash'


class TopStories extends Component {

    RenderStories = () => {
        let sortData = []
        if (this.props.top_stories) {
            sortData = _.sortBy(this.props.top_stories, ["type", "count"]).reverse();
        }
        return this.props.top_stories && _.map(sortData, (item, key) => {
            return (
                <li key={key}>
                    <div className="title_wrapper">
                        <span className="top_stories_list_title">{item.story_name}</span>
                    </div>
                    <div className="count_wrapper">
                        <span>{item.count}</span>
                    </div>
                </li>
            )
        })
    }

    render() {
        return (
            <div className="top_stories_wrapper">
                <div className="card card_hover">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="title " >Top Stories</span>
                                <span className="slogan " >Represented by Number of Times Read</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 ">
                                <span className="stories_header">Stories</span>
                                <ul className="stories_list">
                                    {this.RenderStories()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default TopStories;