import { STORIES_READ } from '../Actions/type'
const INITIAL_STATE = {
    stories_read: {},

}
const storiesReadReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case STORIES_READ:
            return { ...state, ...INITIAL_STATE, 'stories_read': action.data }
        default:
            return state
    }
};

export default storiesReadReducers;