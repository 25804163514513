import { connect } from 'react-redux'
import Main from '../Component/Main'
import * as actions from "../../../Actions/index";



const mapStateToProps = (state) => {

    return {
        loader: state.Common.loader,
        avg_count: state.AvgCount.avg_count,
        stories_count: state.StoriesCount.stories_count,
        prompts_count: state.PromptsCount.prompts_count,
        top_stories: state.TopStories.top_stories.top_stories,
        stories_read: state.StoriesRead.stories_read.stories_read,
        time_spent_in_app: state.TimeSpentInApp.time_spent_in_app.time_spent_in_app,
        prompts_read: state.PromptsRead.prompts_read.prompts_read,
        top_stories_table: state.TopStoriesTable.top_stories_table.top_stories_read,
        favourite: state.Favourites.favourite.favourites,
        favourite_stories_read: state.FavReadTable.fav_stories_table.favourite_stories_read,
        favourite_list: state.FavList.fav_list.favourite_stories,
        data_type: state.Filter.data_type,
        filter_age: state.ApiFilter.filter_age,
        filter_date: state.ApiFilter.filter_date,
        filter_code: state.ApiFilter.filter_code,
        nick_name: state.ApiFilter.nick_name,
        profile: state.Profile.details,
        getorg: state.GetOrg,
        all_goals:state.GetAllGoals.all_goals
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

        GetHcode: () => {
            dispatch(actions.GetHcode())
        },

        // genral Fucntion
        getAverageCount: (data) => {
            dispatch(actions.getAverageCount(data))
        },
        getStoriesCount: (data) => {
            dispatch(actions.getStoriesCount(data))
        },
        getPromptsCount: (data) => {
            dispatch(actions.getPromptsCount(data))
        },
        getFavStories: (data) => {
            dispatch(actions.getFavStories(data))
        },
        getFavStoriesList: (data) => {
            dispatch(actions.getFavStoriesList(data))
        },
        getTopStories: (data) => {
            dispatch(actions.getTopStories(data))
        },

        //Table Fucntion
        getStoriesRead: (data) => {
            dispatch(actions.getStoriesRead(data))
        },
        getTimeSpentInApp: (data) => {
            dispatch(actions.getTimeSpentInApp(data))
        },
        getPromptsRead: (data) => {
            dispatch(actions.getPromptsRead(data))
        },
        getFavStoriesTable: (data) => {
            dispatch(actions.getFavStoriesTable(data))
        },
        getTopStoriesTable: (data) => {
            dispatch(actions.getTopStoriesTable(data))
        },
        getAllGoals: (org_code) => {
            dispatch(actions.getAllGoals(org_code))
        },
        updateOrgCode: (data) => {
            dispatch(actions.updateOrgCode(data))
        },

        //Filter Fucntion
        summaryData: (value) => {
            dispatch(actions.summaryData(value))
        },
        FilterByAge: (value, nick_name) => {
            dispatch(actions.FilterByAge(value, nick_name))
        },
        FilterByDate: (value, nick_name) => {
            dispatch(actions.FilterByDate(value, nick_name))
        },
        FilterByOrgCode: (value, nick_name) => {
            dispatch(actions.FilterByOrgCode(value, nick_name))
        },
        stopLoader: () => {
            dispatch(actions.stopLoader())
        }

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Main)