import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import './assets/css/main.css';
import Header from './Global/Component/Header'
import Dashboard from './Pages/Dashboard/Container'
import Login from './Pages/Login/Container'
import Setting from './Pages/Setting/Container'



function App() {
  
  return (
    <div>
      <BrowserRouter >
        <Header/>
        <React.Fragment>
          <Route exact path='/' component={Login} />
          <Route path='/setting' component={Setting} />
          <Route path='/dashboard' component={Dashboard} />
        </React.Fragment>
      </BrowserRouter>
    </div>
  );
}

export default App;
