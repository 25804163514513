import React, { Component } from 'react';
import Select from 'react-select';
import { ExportToCsv } from 'export-to-csv';
import _ from 'lodash'



const data = [];
const age_data = [
    { 'age': 'all_ages', 'label': 'All Ages' },
    { 'age': 'baby', 'label': '6-18 months' },
    { 'age': 'toddler', 'label': '12-24 months' },
    { 'age': 'preschooler', 'label': '2-4 years' }
]
class Filter extends Component {



    exportData = () => {
        // Section Wise

        // top_stories
        if (this.props.top_stories && this.props.top_stories.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'TopStories',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Top Stories',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

            });
            csvExporter.generateCsv(this.props.top_stories);
        }

        // avg_count
        if (this.props.avg_count.hours && this.props.avg_count.hours.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'AvgCount',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Avg Count',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

            });
            csvExporter.generateCsv(this.props.avg_count.hours);
        }

        // stories_count
        if (this.props.stories_count.stories) {
            const csvExporter = new ExportToCsv({
                filename: 'Stories',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Stories',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

            });
            csvExporter.generateCsv(this.props.stories_count.stories);
        }

        // prompts_count
        if (this.props.prompts_count.prompts) {
            const csvExporter = new ExportToCsv({
                filename: 'PromptsCount',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Prompts Count',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

            });
            csvExporter.generateCsv(this.props.prompts_count.prompts);
        }

        // time_spent_in_app
        if (this.props.time_spent_in_app && this.props.time_spent_in_app.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'TimeSpentInApp',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Time Spent In App',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,

            });
            csvExporter.generateCsv(this.props.time_spent_in_app);
        }

        // stories_read
        if (this.props.stories_read && this.props.stories_read.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'StoriesRead',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Stories Read',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.stories_read);
        }

        // prompts_read
        if (this.props.prompts_read && this.props.prompts_read.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'PromptsRead',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Prompts Read',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.prompts_read);
        }

        // top_stories_table
        if (this.props.top_stories_table && this.props.top_stories_table.length) {
            const csvExporter = new ExportToCsv({
                filename: 'TotalStories',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Total Stories',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.top_stories_table);
        }


        // favourite
        if (this.props.favourite) {
            const csvExporter = new ExportToCsv({
                filename: 'favorite',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Favorite',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.favourite);
        }

        if (this.props.favourite_list) {

        }

        if (this.props.favourite_stories_read) {
        }


        // favourite_list
        if (this.props.favourite_list.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'favoritelist',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'Favorite List',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.favourite_list);
        }

        // favourite_stories_read

        if (this.props.favourite_stories_read && this.props.favourite_stories_read.length > 0) {
            const csvExporter = new ExportToCsv({
                filename: 'favorite_stories_read',
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: true,
                title: 'favorite stories read',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
            });
            csvExporter.generateCsv(this.props.favourite_stories_read);
        }
    }

    renderCode = () => {
        let OrgCode = []
        if (Object.keys(this.props.getorg.org_code).length > 0) {
            OrgCode.push({ org_code: 'all', label: 'All' })
            _.map(this.props.getorg.org_code, (i) => {
                OrgCode.push({ org_code: i.org_code, label: i.nick_name ? i.nick_name : i.org_code })
            })
            return (
                <Select
                    defaultValue={{ label: this.props.getorg.org_code[0].nick_name ? this.props.getorg.org_code[0].nick_name : this.props.getorg.org_code[0].org_code, value: this.props.getorg.org_code[0].org_code }}
                    onChange={this.props.handleChange}
                    options={OrgCode}
                    theme={(theme) => ({
                        ...theme,

                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: '#88909e',
                            primary25: '#e5e5e5',

                        },

                    })
                    } />
            )
        }


    }

    render() {

        return (

            <div className="filter_wrapper" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-xl-10 align-self-center">
                            <form>
                                <div className="row">
                                    <div className="col-md-3 col-xl-3">
                                        <div className="form-group">
                                            <label htmlFor="datatype">Filter by Data</label>
                                            <ul className="custom_tab">
                                                <li onClick={(e) => { this.props.onDataTypeChange('overview') }} className={this.props.data_type === 'user_data' ? '' : 'active'}><span>Overview</span></li>
                                                <li onClick={(e) => { this.props.onDataTypeChange('user_data') }} className={this.props.data_type === 'user_data' ? 'active' : ''}><span>Users</span></li>
                                            </ul>

                                        </div>
                                    </div>

                                    <div className="col-md-2 col-xl-3">
                                        <div className="form-group">
                                            <label htmlFor="sel1">Filter by Organization Code</label>
                                            {this.renderCode()}

                                        </div>
                                    </div>

                                    <div className="col-md-5 col-xl-4">
                                        <div className="form-group">
                                            <label htmlFor="time">Filter by Time</label>
                                            <ul className="custom_tab">
                                                <li onClick={(e) => { this.props.onChageByDate('past_month') }} className={this.props.filter_date === 'past_month' ? 'active' : ''}><span>Past Month</span></li>
                                                <li onClick={(e) => { this.props.onChageByDate('past_year') }} className={this.props.filter_date === 'past_year' ? 'active' : ''}><span>Past Year</span></li>
                                                <li onClick={(e) => { this.props.onChageByDate('all_time') }} className={this.props.filter_date === 'all_time' ? 'active' : ''}><span>All Time</span></li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xl-2">
                                        <div className="form-group">
                                            <label htmlFor="age">Filter by Age</label>



                                            <Select
                                                // defaultValue={{ label: organization[0].nick_name, value: organization[0].nick_name }}
                                                onChange={this.props.onChageByAge}
                                                options={age_data}
                                                theme={(theme) => ({
                                                    ...theme,

                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: '#88909e',
                                                        primary25: '#e5e5e5',

                                                    },

                                                })
                                                } />


                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                        <div className="col-md-12 col-xl-2 align-self-center">
                            <div className="row justify-content-end">
                                <div className="col-md-12 col-lg-12 text-md-center">
                                    <div className="file_export_wraper" onClick={(e) => { this.exportData() }}>
                                        <i className="fa fa-file-excel-o"></i> <span>Export as .csv</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        );
    }
}

export default Filter;