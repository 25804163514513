import React, { Component } from 'react';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';


let options = {
    page: 1,  // which page you want to show as default
    sizePerPageList: [{
        text: '5', value: 5
    }, {
        text: '10', value: 10
    }, {
        text: 'All',
        // value: products.length
    }],
    // you can change the dropdown list for size per page
    sizePerPage: 10,  // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: '<<', // First page button text
    lastPage: '>>', // Last page button text
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    hideSizePerPage: true //> You can hide the dropdown for sizePerPage

};

class TimeSpentInApp extends Component {




    render() {
        return (

            <BootstrapTable data={this.props.time_spent_in_app ? this.props.time_spent_in_app : []} options={options} striped hover pagination>
                <TableHeaderColumn isKey dataField='email' dataSort >Email</TableHeaderColumn>
                <TableHeaderColumn dataField='hour_per_user' dataSort >Hour Per User</TableHeaderColumn>
                <TableHeaderColumn dataField='average_minutes_per_session' dataSort >Minutes Per Session</TableHeaderColumn>
                <TableHeaderColumn dataField='times_per_week' dataSort >Times per Week</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

export default TimeSpentInApp;