import React, { Component } from 'react';
import logo from '../../assets/images/logo.png'
import { connect } from 'react-redux'
import * as actions from "../../Actions/index";
import { Link } from 'react-router-dom'


let isUserLoggedIn = JSON.parse(localStorage.getItem("ecrypt_data"))
    ? JSON.parse(localStorage.getItem("ecrypt_data"))
    : "";
    let user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setting: false,

        }

    }


    updateProfile = (e) => {
        e.preventDefault();
        let fname = this.refs.fname.value
        let lname = this.refs.lname.value
        let password = this.refs.password.value
        let data = { 'first_name': fname, 'last_name': lname, 'password': password };
        if (password === '') {
            delete data.password
        }
        if (fname !== "" || lname !== "") {

            if (fname !== isUserLoggedIn.first_name || lname !== isUserLoggedIn.last_name) {

                this.props.updateProfile(data);
            }
            else {
            }
        }
        if (password !== '') {
            this.props.updateProfile(data);
        }

    }

    componentWillReceiveProps = (props) => {
        if (Object.keys(props.profile).length > 0) {
            this.setState({ setting: false })
        }
    }

    openSetting = (e) => {
        this.setState({
            setting: !this.state.setting
        })

    }

    closeProfleMsg = () => {
        this.props.closeProfleMsg()
    }

    render() {
        return (
            <div className="header_container fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light ">
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                                    <Link to="/dashboard" className="navbar-brand">
                                        <img src={logo} alt="RAC" style={{ "height": "40px", "marginLeft": "-11px" }} />
                                    </Link>
                                    
                                    {isUserLoggedIn ?
                                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 right_nav">
                                            <li className="nav-item ">
                                                <a className="nav-link" href="#"> <span className="user_name"> {user}  </span> </a>
                                            </li>

                                            <li className="nav-item" onClick={(e) => { this.props.logout() }}>
                                                <a className="nav-link" href="#" > <span className="user_name">{'Logout'}</span></a>
                                            </li>
                                            <li className="nav-item" onClick={(e) => { this.openSetting(e) }}>
                                                <Link className="nav-link" to="/setting" > <i className="fa fa-cog"></i></Link>

                                            </li>

                                        </ul>
                                        : ''}
                                </div>
                            </nav>
                        </div>
                    </div>

                </div>
                {Object.keys(this.props.profile).length > 0 ?
                    <div className="toast toast--green " >
                        {/* <div className="toast__icon">
                        </div> */}
                        <div className="toast__content">
                            {/* <p className="toast__type">Success</p> */}
                            <p className="toast__message">{this.props.profile.message}</p>
                        </div>
                        <div className="toast__close">
                            <i className="fa fa-close" onClick={(e) => { this.closeProfleMsg() }}></i>
                        </div>
                    </div>
                    : ''}



                <div className="border_header"></div>
            </div >
        );
    }
}


const mapStateToProps = (state) => {
    
    return {
        profile: state.Profile.details
    }
}


const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(actions.logout())
        },
        updateProfile: (data) => {
            dispatch(actions.updateProfile(data))
        },
        closeProfleMsg: () => {
            dispatch(actions.closeProfleMsg())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)