import React, { Component } from 'react';

class OverAllCount extends Component {
    render() {
        return (
            <div className="average_count_wrapper">
                <div className="row">
                    <div className="col-md-6 col-xl-3 col-lg-3">
                        <div className="card card-primary card_hover">
                            <div className="card-body">
                                <span className="count">{this.props.avg_count.hours && this.props.avg_count.hours[0].total_hours_in_app ? this.props.avg_count.hours[0].total_hours_in_app : 0}</span>

                                <span className="title text-primary">Total Hours in App</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-lg-3">
                        <div className="card card-info card_hover">
                            <div className="card-body">
                                <span className="count">{this.props.avg_count.hours && this.props.avg_count.hours[0].average_hours_per_user ? this.props.avg_count.hours[0].average_hours_per_user : 0}</span>
                                <span className="title text-info">Average Hours per User</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-lg-3">
                        <div className="card card-success card_hover">
                            <div className="card-body">
                                <span className="count">{this.props.avg_count.hours && this.props.avg_count.hours[0].average_minutes_per_session? this.props.avg_count.hours[0].average_minutes_per_session : 0}</span>
                                <span className="title text-success">Average Minutes per Session</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-lg-3">
                        <div className="card card-warning card_hover">
                            <div className="card-body">
                                <span className="count">{this.props.avg_count.hours && this.props.avg_count.hours[0].times_per_week? this.props.avg_count.hours[0].times_per_week : 0}</span>
                                <span className="title text-warning">Average Times per Week</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default OverAllCount;