import { TIME_SPENT_IN_APP } from '../Actions/type'
const INITIAL_STATE = {
    time_spent_in_app: {},

}
const TimeSpentInApp = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TIME_SPENT_IN_APP:
            return { ...state, ...INITIAL_STATE, 'time_spent_in_app': action.data }
        default:
            return state
    }
};

export default TimeSpentInApp;