import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const options = {
    page: 1,  // which page you want to show as default
    sizePerPageList: [{
        text: '5', value: 5
    }, {
        text: '10', value: 10
    }, {
        text: 'All',
        // value: products.length
    }],
    // you can change the dropdown list for size per page
    sizePerPage: 10,  // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    paginationSize: 3,  // the pagination bar size.
    prePage: '<', // Previous page button text
    nextPage: '>', // Next page button text
    firstPage: '<<', // First page button text
    lastPage: '>>', // Last page button text
    paginationPosition: 'bottom',  // default is bottom, top and both is all available
    hideSizePerPage: true //> You can hide the dropdown for sizePerPage

};
class AllGoals extends Component {
    
    render() {

        return (
            <div className="card card_top_border_info card_hover">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="table_title">All Goals</span>
                            <span className="table_slogan"></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <BootstrapTable data={this.props.all_goals.data ? this.props.all_goals.data : []} options={options} striped hover pagination>
                                <TableHeaderColumn isKey dataField='account' dataSort width={'30%'}>Account</TableHeaderColumn>
                                <TableHeaderColumn dataField='goal_completed' dataSort width={'10%'}>Goal Completed</TableHeaderColumn>
                                <TableHeaderColumn dataField='goal_frequency_mpd' dataSort >Goal Frequency mpd</TableHeaderColumn>
                                <TableHeaderColumn dataField='goal_frequency_tpw' dataSort >Goal Frequency tpw</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AllGoals;