import Login from '../Component/Login'
import * as actions from "../../../Actions/index";
import { connect } from 'react-redux'
import { stat } from 'fs';



const mapStateToProps = (state) => {
//   console.log(state.login.message)
    return {
        failed:state.Login,
        loader: state.Common.loader,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        login: (user) => {
            dispatch(actions.login(user))
        },


    }
}
export default (connect(mapStateToProps, mapDispatchToProps)(Login));