import { combineReducers } from 'redux';
import Common from './CommonReducers'

import AvgCount from './AverageReducers'
import StoriesCount from './StoriesReducers'
import TopStories from './TopStories'
import TimeSpentInApp from './TimeSpentInApp'
import PromptsCount from './PromptsCountReducers'
import StoriesRead from './StoriesReadReducers'
import PromptsRead from './PromptsReadReducers'
import Filter from './FilterReducers'
import TopStoriesTable from './TopStoriesTableReducers'
import ApiFilter from './ApiFilterReducers'
import FavReadTable from './FavStoriesTable'
import Favourites from './FavouriteReducers'
import FavList from './FavListReducers'
import Login from './LoginReducers'
import Profile from './ProfileUpdateReducers'
import OrgCode from './OrgCode'
import GetOrg from './GetOrgCode'
import Pagination from './Pagination'
import GetAllGoals from './GetAllGoals'


export default combineReducers({
    Login,
    AvgCount,
    PromptsCount,
    StoriesCount,
    TopStories,
    TimeSpentInApp,
    StoriesRead,
    PromptsRead,
    Filter,
    TopStoriesTable,
    ApiFilter,
    FavReadTable,
    Favourites,
    FavList,
    Common,
    Profile,
    OrgCode,
    GetOrg,
    Pagination,
    GetAllGoals
});
