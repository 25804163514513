import React, { Component } from 'react';
import PromptsRead from './PromptsRead'
import TopStoriesTable from './TopStoriesTable'

class PrompAndTop extends Component {
    render() {
       
        return (
            <div className="table_wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card_top_border_info card_hover">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span className="table_title">Number of Prompts Read</span>
                                        <span className="table_slogan">Represent by Total Number of Prompts Read and Average per Story</span>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        < PromptsRead {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card_top_border_info card_hover">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <span className="table_title">Titles of Number of Stories Read</span>
                                        <span className="table_slogan">Represent by Times Read</span>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TopStoriesTable {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default PrompAndTop;