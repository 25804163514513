import React, { Component } from 'react';

class Promate extends Component {
    render() {
        return (
            <div className="promate_wrapper">
                <div className="row">
                    <div className="col-md-6 col-xl-12">
                        <div className="card bg-success card_hover">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="align-self-center promote_card_icon"> <i className="fa fa-comments-o"></i></div>
                                    <div className="align-self-center promote_tilte">
                                        <h4 className="mb-0">Number of Prompts Read</h4>
                                        <span>Total Number Read</span>
                                    </div>
                                    <div className="ml-auto align-self-center">
                                        <h2 className="font-medium mb-0 count">{this.props.prompts_count.prompts && this.props.prompts_count.prompts[0].total_prompts_read ? this.props.prompts_count.prompts[0].total_prompts_read : 0}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-xl-12">
                        <div className="card bg-warning card_hover">
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <div className="align-self-center promote_card_icon"> <i className="fa fa-comments-o"></i></div>
                                    <div className="align-self-center promote_tilte">
                                        <h4 className="mb-0">Number of Prompts Read</h4>
                                        <span>Average Read per Story</span>
                                    </div>
                                    <div className="ml-auto align-self-center">
                                        <h2 className="font-medium mb-0 count">{this.props.prompts_count.prompts && this.props.prompts_count.prompts[0].average_prompts_read_per_story ? this.props.prompts_count.prompts[0].average_prompts_read_per_story : 0}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xl-12 col-lg-6">
                        <div className="card card_hover">
                            <div className="card-body">

                                <span className="favourite_count">
                                    {this.props.favourite && this.props.favourite[0].total_favourite_stories ? this.props.favourite[0].total_favourite_stories : 0}
                                </span>
                                <span className="favourite_count_title">Total Favorited Stories</span>

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-xl-12 col-lg-6">
                        <div className="card card_hover">
                            <div className="card-body">
                                <span className="favourite_count">
                                    {this.props.favourite && this.props.favourite[0].average_favourites_per_user ? this.props.favourite[0].average_favourites_per_user : 0}
                                </span>
                                <span className="favourite_count_title">Average Favorites per User</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Promate;