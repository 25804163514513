import React, { Component } from 'react';
import authbg from '../../../assets/images/auth-bg.jpg'
import logo from '../../../assets/images/logo-icon.png'


const isUserLoggedIn = JSON.parse(localStorage.getItem("ecrypt_data"))
    ? JSON.parse(localStorage.getItem("ecrypt_data"))
    : "";

class Login extends Component {
    login = (e) => {
        e.preventDefault();
        if (this.refs.email && this.refs.password) {
            let user = { "email": this.refs.email.value, "password": this.refs.password.value, "is_dataportal": true }
            this.props.login(user)
        }

    }

    componentWillMount = () => {
        if (isUserLoggedIn !== "") {
            window.location.assign('/dashboard')

        }
        else {

        }
    }

    render() {
        
        return (
            <div className="auth-wrapper d-flex justify-content-center align-items-center"
                style={{ "background": `url(${authbg}) no-repeat center center` }}>
                <div className="auth-box">
                    <div id="loginform">
                        <div className="logo">
                            <span className="db"><img src={logo} alt="logo" /></span>
                            <h5 className="font-medium mb-3">Sign In to Admin</h5>
                        </div>

                        <div className="row">
                            <div className="col-12">

                                <form className="form-horizontal mt-3" id="loginform" onSubmit={(e) => { this.login(e) }}>
                                    {Object.keys(this.props.failed.failed).length > 0 && <div className="alert alert-danger">{this.props.failed.failed.message}</div>}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-user-o"></i></span>
                                        </div>
                                        <input type="text" className="form-control form-control-lg" ref="email" placeholder="Username" aria-label="Username" />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon2"><i className="fa fa-pencil"></i></span>
                                        </div>
                                        <input type="password" className="form-control form-control-lg" ref="password" placeholder="Password" aria-label="Password" />
                                    </div>

                                    <div className="form-group text-center">
                                        <div className="col-xs-12 pb-3">
                                            <button className="btn btn-block btn-lg btn-primary" type="submit">Log In</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.loader ? <div className="loading"></div> : ''}
            </div>
        );
    }
}


export default Login

