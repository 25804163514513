import React, { Component } from 'react';
import Filter from '../Component/Filter'
import TopStories from '../Component/TopStories'
import Favourites from '../Component/Favourites'
import AverageCount from '../Component/AverageCount'
import OverAllCount from '../Component/OverAllCount'
import Promate from '../Component/Promate'
import Table from '../Component/DataTable'
import PrompAndTop from '../Component/PrompAndTop'
import FavouriteTable from '../Component/FavouriteTable'
import AllGoals from '../Component/AllGoals'
import _ from 'lodash'

let isUserLoggedIn = JSON.parse(localStorage.getItem("ecrypt_data"))
    ? JSON.parse(localStorage.getItem("ecrypt_data"))
    : "";

class Main extends Component {
    componentWillMount = () => {
        if (isUserLoggedIn !== "") {
            let data = { "org_code": isUserLoggedIn.data.length > 0 && isUserLoggedIn.data[0].org_code, "age": this.props.filter_age, 'date': this.props.filter_date }
            this.props.GetHcode();
            this.callAllApis(data);

        }
        else {
            localStorage.clear();
            window.location.assign('/')
        }

    }
  


    callAllApis = (data) => {
        this.props.getAverageCount(data);
        this.props.getStoriesCount(data);
        this.props.getPromptsCount(data);
        this.props.getFavStories(data);
        this.props.getFavStoriesList(data);
        this.props.getTopStories(data);

    }


    userData = (data) => {
        this.props.getStoriesRead(data);
        this.props.getTimeSpentInApp(data);
        this.props.getPromptsRead(data);
        this.props.getFavStoriesTable(data);
        this.props.getTopStoriesTable(data);
        this.props.getAllGoals(data.org_code[0]);
    }

    handleChange = (selectedOption) => {
        this.props.FilterByOrgCode(selectedOption.org_code, selectedOption.label)
        let data = { "org_code": selectedOption.org_code, "age": this.props.filter_age, 'date': this.props.filter_date }
        this.callAllApis(data);
        this.userData(data);

    }

    onDataTypeChange = (value) => {
        console.log("check", this.props.filter_code, isUserLoggedIn.data)
        this.props.summaryData(value)
        let data = { "org_code": this.props.filter_code ? this.props.filter_code : isUserLoggedIn.data.length > 0 ? isUserLoggedIn.data[0].org_code : '', "age": this.props.filter_age, 'date': this.props.filter_date }
        if (value === 'user_data' && !this.props.time_spent_in_app && !this.props.stories_read && !this.props.prompts_read && !this.props.top_stories_table) {
            this.userData(data)
        }

    }

    onChageByAge = (selectedOption) => {

        this.props.FilterByAge(selectedOption.age, this.props.nick_name);

        let data = { "org_code": this.props.filter_code ? this.props.filter_code : isUserLoggedIn.data[0].org_code, "age": selectedOption.age, 'date': this.props.filter_date }
        this.callAllApis(data)
        this.userData(data)
    }

    onChageByDate = (value) => {

        this.props.FilterByDate(value, this.props.nick_name);
        let data = { "org_code": this.props.filter_code ? this.props.filter_code : isUserLoggedIn.data[0].org_code, "age": this.props.filter_age, 'date': value }

        this.callAllApis(data)
        this.userData(data)
    }

    updateOrgCode = (old_code, updated_code) => {

        let form_data = { "username": isUserLoggedIn.username, "org_code": old_code, "nick_name": updated_code }
        this.props.updateOrgCode(form_data)


    }

    render() {

        return (
            <div>

                <div className="container-fluid">
                    <Filter {...this.props}
                        onDataTypeChange={(e) => { this.onDataTypeChange(e) }}
                        onChageByDate={(e) => { this.onChageByDate(e) }}
                        onChageByAge={(e) => { this.onChageByAge(e) }}
                        handleChange={(e) => { this.handleChange(e) }}

                        updateOrgCode={(old_code, new_code) => { this.updateOrgCode(old_code, new_code) }}
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <AverageCount {...this.props} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <OverAllCount {...this.props} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <TopStories {...this.props} />
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                            <Favourites {...this.props} />
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-4">
                            <Promate {...this.props} />
                        </div>
                    </div>

                    {this.props.data_type === 'user_data' ?
                        <Table {...this.props} /> : ''}

                    {this.props.data_type === 'user_data' ?
                        <PrompAndTop {...this.props} /> : ''}
                    <div className="table_wrapper">
                        <div className="row justify-content-end">
                            <div className="col-md-6">
                                {this.props.data_type === 'user_data' ?
                                    <FavouriteTable {...this.props} /> : ''}
                            </div>
                            <div className="col-md-6">
                                {this.props.data_type === 'user_data' ?
                                    <AllGoals {...this.props} /> : ''}
                            </div>
                        </div>
                    </div>
                    {this.props.loader ? <div className="loading"></div> : ''}


                </div>
            </div>
        );
    }
}

export default Main;