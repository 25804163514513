import React, { Component } from 'react';

class AverageCount extends Component {
    render() {
        return (
            <div className="over_all_count_wrapper">
                <div className="row">
                    <div className="col-xl-4 col-md-4">
                        <div className="card bg-primary card_hover">
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2 className="count">{this.props.stories_count.stories && this.props.stories_count.stories[0].total_stories_read ? this.props.stories_count.stories[0].total_stories_read : 0}</h2>
                                        <h6 className="title">Total Number of Stories Read</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="icon"><i className="fa fa-book"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="card bg-info card_hover">
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2 className="count">{this.props.stories_count.stories && this.props.stories_count.stories[0].average_stories_read_per_user ? this.props.stories_count.stories[0].average_stories_read_per_user : 0}</h2>
                                        <h6 className="title">Average Number of Stories Read per User</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="icon"><i className="fa fa-user"></i></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4">
                        <div className="card bg-dark card_hover">
                            <div className="card-body">
                                <div className="d-flex no-block align-items-center">
                                    <div>
                                        <h2 className="count">{this.props.stories_count.stories && this.props.stories_count.stories[0].average_stories_read_per_session ? this.props.stories_count.stories[0].average_stories_read_per_session : 0}</h2>
                                        <h6 className="title">Average Number of Stories Read per Session</h6>
                                    </div>
                                    <div className="ml-auto">
                                        <span className="icon"><i className="fa fa-list"></i></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AverageCount;
