import { FAVOURITES } from '../Actions/type'
const INITIAL_STATE = {
    favourite: {},
}
const FavouritesReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FAVOURITES:
            return { ...state, ...INITIAL_STATE, 'favourite': action.data }
      

        default:
            return state
    }
};

export default FavouritesReducers;