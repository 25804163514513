import axios from 'axios'
import config from "../common/config";
import _ from 'lodash'
import { START_LOADER, STOP_LOADER, TOP_STORIES, STORIES, TIME_SPENT_IN_APP, AVERAGE_COUNT, PROMPTS, STORIES_READ, PROMPTS_READ, DATA_TYPE, TOP_STORIES_TABLE, FILTER_AGE_TYPE, FILTER_DATE_TYPE, FILTER_ORG_CODE_TYPE, FAVOURITE_STORIES_READ_TABLE, FAVOURITES, FAVOURITES_LIST, LOGGED_FAILED, LOGGED_SUCCESSFULLY, UPDATE_PROFILE_SUCCESS, CLOSE_PROFILE_MESSAGE, UPDATE_ORG_CODE, UPDATE_PROFILE_VALIDATE, GET_ORG_CODE, PAGINATION, NEXT_PAGE, GET_ALL_GOALS } from './type';

const djangoToken = JSON.parse(localStorage.getItem("ecrypt_data"))
    ? JSON.parse(localStorage.getItem("ecrypt_data")).token
    : "";

let baseHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `token ${djangoToken}`
}
let err_message = ''


export const login = (user) => {

    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${process.env.REACT_APP_ROOT_URL_LOGIN}`, user)
            .then(response => {

                dispatch({ type: LOGGED_SUCCESSFULLY, data: response.data });
                localStorage.setItem('ecrypt_data', JSON.stringify(response.data));
                localStorage.setItem('organization', JSON.stringify(response.data.data));
                localStorage.setItem('user', JSON.stringify(user.email));

                window.location.assign('/dashboard')
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data)
                    dispatch(stopLoader());
                    dispatch({ type: LOGGED_FAILED, data: error.response.data });
                }
            });

    };
};
export const logout = () => {
    localStorage.clear();
    window.location.assign('/');
    // return (dispatch) => {
    //     dispatch(startLoader());
    //     return axios.get(`${process.env.REACT_APP_ROOT_URL_LOGIN}signout/`, { headers: baseHeaders })
    //         .then(response => {
    //             localStorage.clear();
    //             window.location.assign('/')
    //         })
    //         .catch(error => {
    //             if (error.response) {
    //                 dispatch(stopLoader());
    //             }
    //         });

    // };
};




// average count
export const getAverageCount = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/hours`, data, { headers: baseHeaders })
            .then(response => {

                dispatch({ type: AVERAGE_COUNT, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};



// top_stories
export const getTopStories = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/top_stories`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: TOP_STORIES, data: response.data });
                dispatch(stopLoader());
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// stories
export const getStoriesCount = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/stories`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: STORIES, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// prompts
export const getPromptsCount = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/prompts`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: PROMPTS, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// stories_read
export const getStoriesRead = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/stories_read`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: STORIES_READ, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};



// time_spent_in_app
export const getTimeSpentInApp = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/time_spent_in_app`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: TIME_SPENT_IN_APP, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};




// prompts_read
export const getPromptsRead = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/prompts_read`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: PROMPTS_READ, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// prompts_read
export const getTopStoriesTable = (data) => {
    return async (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/top_stories_read`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: TOP_STORIES_TABLE, data: response.data });
                dispatch(stopLoader());
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


//favourite_stories_read
export const getFavStoriesTable = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/favourite_stories_read`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: FAVOURITE_STORIES_READ_TABLE, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};



//favourite_stories_read
export const getFavStories = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/favourites`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: FAVOURITES, data: response.data });
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};



// favourite_stories
export const getFavStoriesList = (data) => {
    return (dispatch) => {
        dispatch(startLoader());
        return axios.post(`${config.getHost()}/favourite_stories`, data, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: FAVOURITES_LIST, data: response.data });

            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// update org code
export const updateOrgCode = (data) => {

    return (dispatch) => {
        dispatch(startLoader());
        return axios.patch(`${config.getHost()}/update_nickname/change_nickname`, data, { headers: baseHeaders })
            .then(response => {
                // console.log('response::',response.data.data)
                localStorage.setItem('organization', JSON.stringify(response.data.data));
                dispatch(GetHcode());
                dispatch({ type: UPDATE_ORG_CODE, data: response.data })

                // window.location.reload();
                dispatch(stopLoader());

            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};


// update org code
export const updateProfile = (data) => {

    // if (data.password === "" || data.new_password === "" || data.confirm_new_pwd === "") {
    //     err_message = 'All field are required'
    // }

    if (data.password.toString() !== data.confirm_password.toString()) {
        err_message = "Password does't match"
    }
    else {
        err_message = ""
    }
    return (dispatch) => {

        if (err_message) {
            dispatch({ type: UPDATE_PROFILE_VALIDATE, data: err_message })
        }
        else {
            err_message = "";
            dispatch(startLoader());
            return axios.patch(`${config.getHost()}/change_dataportal_password`, data, { headers: baseHeaders })
                .then(response => {
                    dispatch({ type: UPDATE_PROFILE_SUCCESS, data: response.data })
                    dispatch(stopLoader());
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            dispatch({ type: UPDATE_PROFILE_SUCCESS, data: error.response.data })
                            dispatch(stopLoader());
                        }
                        else {
                            dispatch(stopLoader());
                            console.log("Error:", error.response)
                        }

                    }
                });
        }



    };
};

export const closeProfleMsg = () => {
    return {
        type: CLOSE_PROFILE_MESSAGE,
        data: {}
    }
}

// Filter
export const summaryData = (value) => {
    return {
        type: DATA_TYPE,
        value: value
    }
}
export const FilterByAge = (value, nick_name) => {

    return {
        type: FILTER_AGE_TYPE,
        value: value,
        nick_name: nick_name
    }
}

export const GetHcode = () => {
    let OrgCode = []
    let data = JSON.parse(localStorage.getItem("organization"))
        ? JSON.parse(localStorage.getItem("organization")) : [];

    _.map(data, (i) => {
        OrgCode.push({ org_code: i.org_code, label: i.org_code, nick_name: i.nick_name })
    })



    return {
        type: GET_ORG_CODE,
        data: OrgCode

    }
}




export const FilterByDate = (value, nick_name) => {
    return {
        type: FILTER_DATE_TYPE,
        value: value,
        nick_name: nick_name
    }
}
export const FilterByOrgCode = (value, nick_name) => {

    return {
        type: FILTER_ORG_CODE_TYPE,
        value: value,
        nick_name: nick_name
    }
}



// Common
export const startLoader = () => {
    return {
        type: START_LOADER,
    };
};
export const stopLoader = () => {
    return {
        type: STOP_LOADER,
    };
};

// pagination
export const Pagination = () => {

    return {
        type: PAGINATION,
        currentPage: 1,
        perPage: 10
    };
};

export const NextPage = (currentPage) => {
    return {
        type: NEXT_PAGE,
        currentPage: currentPage
    };

};


// Get All Goals
export const getAllGoals = (org_code) => {
    return (dispatch) => {
        // dispatch(startLoader());
        return axios.get(`${config.getHost()}/account_goals?org_code=${org_code}`, { headers: baseHeaders })
            .then(response => {
                dispatch({ type: GET_ALL_GOALS, data: response.data });

            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        window.location.assign('/')
                    }
                    else {
                        dispatch(stopLoader());
                        console.log("Error:", error.response)
                    }

                }
            });
    };
};