import { PAGINATION, NEXT_PAGE } from '../Actions/type'
const INITIAL_STATE = {
    currentPage: 1,
    perPage: 10,
    

}
const PaginationReducers = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case PAGINATION:
            return { ...state, ...INITIAL_STATE, 'currentPage':action.currentPage, 'perPage': action.perPage,'pageNumbers':action.pageNumbers }
            case NEXT_PAGE:
            return { ...state, ...INITIAL_STATE, 'currentPage':action.currentPage}
        default:
            return state
    }
};

export default PaginationReducers;