import { START_LOADER, STOP_LOADER, UPDATE_SELECTED } from '../Actions/type'
const INITIAL_STATE = {
    loader: false,
   
}
const CommonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case START_LOADER:
            return { ...state, ...INITIAL_STATE, 'loader': true }
        case STOP_LOADER:
            return { ...state, ...INITIAL_STATE, 'loader': false }
        case STOP_LOADER:
            return { ...state, ...INITIAL_STATE, 'loader': false }

        default:
            return state
    }
};

export default CommonReducer;