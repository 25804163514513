import { connect } from 'react-redux'
import Setting from '../Component/Setting'
import * as actions from "../../../Actions/index";





const mapStateToProps = (state) => {
    // console.log("check",state.GetOrg)
    return {
        getorg: state.GetOrg,
        OrgCode: state.OrgCode.data,
        Profile: state.Profile,
        loader: state.Common.loader,
        pagination: state.Pagination,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetHcode: () => {
            dispatch(actions.GetHcode())
        },
        updateOrgCode: (data) => {
            dispatch(actions.updateOrgCode(data))
        },
        updateProfile: (data) => {
            dispatch(actions.updateProfile(data))
        },
        Pagination: () => {
            dispatch(actions.Pagination())
        },
        NextPage: (currentPage) => {
            dispatch(actions.NextPage(currentPage))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(Setting)